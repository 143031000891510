import React, { useMemo, useState, useEffect, useCallback } from "react";
import { debounce } from "lodash";
import {
  ColumnDef,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  flexRender,
} from "@tanstack/react-table";
import ServiceDropdown from "../ServiceDropdown";
import Spinner from "../Spinner";

export enum ServicesType {
  TWITTER = "twitter",
  YOUTUBE = "youtube",
  INSTAGRAM = "instagram",
  TWITCH = "twitch",
  DISCORD = "discord",
}

// Give our default column cell renderer editing superpowers!
const defaultColumn = (handleDelete: Function): Partial<ColumnDef<any>> => ({
  cell: ({ getValue, row, column: { id }, table }) => {
    const { index } = row;

    if (id == "Remove") {
      return (
        <button
          className="p-1 bg-red-800 hover:bg-red-900 rounded-md"
          // @ts-ignore - fix at some point
          onClick={(e) => handleDelete(row)}
        >
          🗑️
        </button>
      );
    }
    const initialValue = getValue() as string;
    // We need to keep and update the state of the cell normally
    const [value, setValue] = useState<string>(initialValue);

    // When the input is blurred, we'll call our table meta's updateData function
    const onChange = (val: string) => {
      (table?.options?.meta as any)?.updateData(index, id, val);
    };

    // If the initialValue is changed external, sync it up with our state
    useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    useEffect(() => {
      onChange(value);
    }, [value]);

    return id == "service" ? (
      <ServiceDropdown
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
      />
    ) : (
      // TODO: validate handle format (should be no spaces, etc.)
      <input
        key={row?.original?.handle?.name + row?.original?.handle?.service}
        className="w-full p-1 bg-white text-black border border-gray-500 rounded p-1 focus:ring-4 focus:ring-sky-500 font-medium"
        defaultValue={value as string}
        placeholder="Enter a handle"
        onChange={debounce((e) => {
          setValue(e.target.value);
        }, 500)}
      />
    );
  },
});

interface ServiceGridProps {
  data: ServicesType | null | undefined;
  onUpdate: (value: any) => void;
  autoSubmit?: boolean;
  loading?: boolean;
}

const ServiceGrid = ({
  data,
  onUpdate,
  autoSubmit = false,
  loading = false,
}: ServiceGridProps) => {
  const [gridData, setGridData] = useState<any>(data);

  const handleDelete = useCallback((e: any) => {
    const { name, service } = e?.original || {};

    setGridData((prevState: any) =>
      prevState?.filter((row: any) => {
        if (row?.handle?.name === name && row?.handle?.service === service) {
          return false;
        } else {
          return true;
        }
      })
    );
  }, []);

  const columns = useMemo<ColumnDef<any>[]>(
    () => [
      {
        accessorFn: (row) => row.handle?.name,
        id: "name",
        header: "Handle",
        width: 150,
      },
      {
        accessorFn: (row) => row.handle?.service,
        id: "service",
        header: "Service",
        width: 80,
      },
      {
        width: 300,
        header: "Remove",
        id: "Remove",
      },
    ],
    []
  );

  const table = useReactTable({
    data: gridData,
    columns,
    defaultColumn: defaultColumn(handleDelete),
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    // Provide our updateData function to our table meta
    meta: {
      updateData: (rowIndex: any, columnId: any, value: any) => {
        setGridData((old: any) =>
          old.map((row: any, index: any) => {
            if (index === rowIndex) {
              return {
                ...old[rowIndex]!,
                [columnId]: value,
              };
            }
            return row;
          })
        );
      },
    },
    // debugTable: true,
  });

  const createNewRow = useCallback(() => {
    setGridData((prevState: any) => [
      ...prevState,
      { handle: { name: "", service: "" } },
    ]);
  }, []);

  useEffect(() => {
    if (autoSubmit) {
      onUpdate(gridData);
    }
  }, [gridData]);

  return (
    <div className="w-full">
      <table className="flex flex-col items-center justify-start items-stretch divide-y divide-solid">
        <thead>
          {table?.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id} className="flex gap-2 md:gap-12 py-2">
              {headerGroup.headers.map((header) => {
                return (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    className="text-left w-1/3 md:w-[150px]"
                  >
                    {header.isPlaceholder ? null : (
                      <div>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </div>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody className="divide-y divide-solid">
          {table?.getRowModel().rows.map((row) => {
            return (
              <tr key={row.id} className="flex gap-2 md:gap-12 py-2">
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td key={cell.id} className="text-left w-1/3 md:w-[150px]">
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>

      <button
        onClick={createNewRow}
        className="w-full py-2 text-white font-bold bg-lime-600 rounded-md hover:bg-lime-800"
      >
        Add Row
      </button>

      {!autoSubmit ? (
        <button
          className="w-full my-8 py-2 text-white font-bold bg-sky-700 rounded-md hover:bg-sky-800"
          disabled={loading}
          onClick={() => onUpdate(gridData)}
        >
          {loading ? (
            <>
              <Spinner />
              Loading...
            </>
          ) : (
            <>Save Changes</>
          )}
        </button>
      ) : null}
    </div>
  );
};

export default ServiceGrid;
