export enum ServicesType {
  TWITTER = "twitter",
  YOUTUBE = "youtube",
  INSTAGRAM = "instagram",
  TWITCH = "twitch",
  DISCORD = "discord",
}

interface AlertProps {
  value: ServicesType | string | null | undefined;
  onChange: (value: any) => void;
}

const ServiceDropdown = ({ value, onChange }: AlertProps) => {
  return (
    <div>
      <select
        className="w-full p-1 bg-white text-black border border-gray-500 rounded p-1 focus:ring-4 focus:ring-sky-500 font-medium"
        name="services"
        id="services"
        // @ts-ignore
        value={value && value?.toLocaleLowerCase()}
        onChange={onChange}
      >
        <option value="twitter" className="font-medium">
          Twitter
        </option>
        <option value="twitch" className="font-medium">
          Twitch
        </option>
        <option value="instagram" className="font-medium">
          Instagram
        </option>
        {/* TODO: enable once we have scanners */}
        {/* <option value="discord">Discord</option>
        <option value="youtube">Youtube</option> */}
      </select>
    </div>
  );
};

export default ServiceDropdown;
