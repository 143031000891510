import { useState } from "react";
import Image from "next/image";
import { groupBy } from "lodash";
import Typewriter from "typewriter-effect";

import TwitterIcon from "../assets/twitter-icon.webp";
import TwitchIcon from "../assets/twitch-icon.webp";
import InstagramIcon from "../assets/instagram-icon.webp";
import Alert, { AlertType } from "../components/common/Alert";
import ServiceGrid from "../components/common/Grid";
import Spinner from "../components/common/Spinner";

export default function Home() {
  const [successMsg, setSuccessMsg] = useState<string | null>();
  const [error, setError] = useState<string | null>();
  const [loading, setLoading] = useState<boolean>(false);
  const [gridData, setGridData] = useState<any>([
    { handle: { name: "", service: "" } },
  ]);

  const submitSignup = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError(null);
    setSuccessMsg(null);
    setLoading(true);

    const { email } = (e.target as any).elements;
    const cfResp = (e?.target as any)?.elements?.["cf-turnstile-response"]
      ?.value;

    const groupedHandles = groupBy(gridData, "name");

    const handlesArrWithoutEmptyRows = Object.entries(groupedHandles)?.filter(
      ([groupedHandleName, data]) => groupedHandleName !== ""
    );

    // forcing user to have atleast one handle
    if (handlesArrWithoutEmptyRows.length === 0) {
      setError("Please add atleast one handle to sign up");
      setLoading(false);
      return;
    }

    const newData = {
      email: email?.value,
      cfResp,
      handles: handlesArrWithoutEmptyRows.map(([groupedHandleName, data]) => {
        return {
          name: groupedHandleName,
          service: data.map((handle) => ({
            serviceName: handle.service,
            frequency: handle.frequency,
          })),
        };
      }),
    };

    fetch(`${process.env.NEXT_PUBLIC_API_URL}/signup`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newData),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          setError(res.error);
        } else {
          setSuccessMsg(res.message);
        }
        setLoading(false);
      });
  };

  return (
    <div className="p-4 flex justify-center">
      <main className="px-4 md:px-12 flex flex-col items-center py-8 gap-10 bg-slate-50 border border-black drop-shadow-2xl max-w-6xl">
        <h1 className="text-5xl md:text-6xl font-bold">
          Grab your perfect social media username with our social media handle
          checker
        </h1>

        <h2 className="text-lg">
          Be the first to know when your favourite social media handle is
          available. Sign up now to receive instant notifications when your
          desired username is up for grabs and secure it before it's gone.
        </h2>

        <form
          className="flex flex-col items-center gap-2 mx-auto"
          onSubmit={submitSignup}
        >
          <div className="font-semibold">
            <p className="p-2">Supported Platforms:</p>
            <div className="flex gap-5">
              <Image src={TwitterIcon} alt="Twitter Icon" height={40} />
              <Image src={TwitchIcon} alt="Twitch Icon" height={40} />
              <Image src={InstagramIcon} alt="Instagram Icon" height={40} />
            </div>
          </div>

          <div className="pb-5">
            <ServiceGrid data={gridData} onUpdate={setGridData} autoSubmit />
          </div>

          <input
            id="email"
            placeholder="Your Email Address"
            type="email"
            required
            className="w-full bg-white text-black border border-gray-500 rounded p-1 focus:ring-4 focus:ring-sky-500 font-medium"
          />

          {/* see: https://developers.cloudflare.com/turnstile/reference/testing/  */}
          <div
            className="cf-turnstile"
            data-sitekey="0x4AAAAAAAGgKxS7OQxv2Gb2" //prod
            // data-sitekey="1x00000000000000000000AA" // passes visible
          ></div>

          {error || successMsg ? (
            <Alert
              type={
                error ? AlertType.ERROR : successMsg ? AlertType.SUCCESS : null
              }
              message={error ? error : successMsg ? successMsg : null}
            />
          ) : null}

          <button
            type="submit"
            disabled={loading}
            className="w-1/2 md:w-1/3 px-10 py-4 text-white font-bold bg-sky-700 rounded-md hover:bg-sky-800"
          >
            {loading ? (
              <>
                <Spinner />
                Loading...
              </>
            ) : (
              <>Get Notified</>
            )}
          </button>
        </form>

        <div className="px-4 md:px-12 justify-center items-center flex flex-col">
          <h2 className="text-3xl font-semibold pb-2">
            How does Handle Grab work?
          </h2>

          <div className="flex text-xl py-2 gap-1">
            You want a{" "}
            <Typewriter
              options={{
                strings: ["funny", "unique", "short"],
                autoStart: true,
                loop: true,
              }}
            />
            social media handle
          </div>

          <div className="py-2">
            <Alert
              type={AlertType.ERROR}
              message="But it&#39;s already taken ☹️"
              hideTitle
            />
          </div>

          {/* TODO: should i remove the other social medias? */}
          {/* TODO: made up the 5k number? maybe change in future */}
          <p className="py-2">
            We&#39;ve made checking the availability of a handle on Twitter,
            Instagram, YouTube and Twitch quick and easy saving you time and the
            hassle of constantly checking. Handle grab currently checks over
            5000 social media usernames. Simply follow these steps to determine
            whether your desired handle is available:
          </p>

          <div className="flex flex-col gap-5 md:flex-row md:gap-14 py-4">
            <div className="md:w-1/4 border border-black p-2">
              <p>
                Use our free checker to make sure your handle isn&#39;t
                available.
              </p>
            </div>
            <div className="md:w-1/4 border border-black p-2">
              <p>Submit the handle&#39;s you want to get.</p>
            </div>
            <div className="md:w-1/4 border border-black p-2">
              <p>
                Handle grab regularly checks the availability of your handles
              </p>
            </div>
            <div className="md:w-1/4 border border-black p-2">
              <p>Handle grab emails you when a handle becomes available.</p>
            </div>
          </div>

          <p className="py-2">
            Sign up now and we&#39;ll start tracking your desired handle
            immediately and email as soon as your handle becomes available.
          </p>
        </div>

        <div className="px-4 md:px-12">
          <h2 className="text-2xl font-bold">Frequently asked questions</h2>

          <p className="py-2 font-semibold underline">
            How often can I check the availability of a handle?
          </p>
          <p className="py-2">
            You can check the availability of a handle for free on a weekly
            basis. However, if you want more frequent updates to reduce the
            chance of missing your opportunity to grab your desired username,
            there&#39;s a fee to get hourly notifications.
          </p>

          <p className="py-2 font-semibold underline">
            How can I contact the support team if I have any issues or
            questions?
          </p>
          <p className="py-2">
            If you have any questions, please email us at admin@handlegrab.com
            and we will be more than happy to answer your questions.
          </p>

          <p className="py-2 font-semibold underline">
            Is it necessary to have a social media account to Use the Handle
            Grab?
          </p>
          <p className="py-2">
            You don&#39;t have to have a social media account to use our
            service. All you need is an email address so that we can notify you
            when the desired username becomes available. We recommend using an
            email address that you regularly use.
          </p>
        </div>
      </main>
    </div>
  );
}
