export enum AlertType {
  SUCCESS = "success",
  WARNING = "warning",
  ERROR = "error",
  INFO = "info",
}

const TYPE_MAP = {
  [AlertType.SUCCESS]:
    "bg-green-100 border-l-4 border-green-500 text-green-700 p-4",
  [AlertType.WARNING]:
    "bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4",
  [AlertType.ERROR]: "bg-red-100 border-l-4 border-red-500 text-red-700 p-4",
  [AlertType.INFO]: "bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4",
};

interface AlertProps {
  type: AlertType | null;
  message: string | null;
  hideTitle?: boolean;
}

const Alert = ({ type, message, hideTitle = false }: AlertProps) => {
  if (!type || !message) return null;

  return (
    <div className={TYPE_MAP[type]} role="alert">
      {!hideTitle && <p className="capitalize font-bold">{type}</p>}
      <p>{message}</p>
    </div>
  );
};

export default Alert;
